import Truck from 'core/entities/Truck/types';

import * as types from 'widgets/Chat/redux/actionTypes';
import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import {Dispatcher, MessageStatus, UnreadInfo} from 'widgets/Chat/types';
import {NormalizedFormFile} from 'widgets/Chat/types/form';
import {DriversGroup} from 'widgets/Chat/types/group';

import * as mappers from '../mappers/index';

type Pagination = {total: number; page: number; perPage: number};

type OpenedDriversGroup = {
    attachmentsToMessage: NormalizedFormFile[] | null;
    isAttachmentsUploading: boolean;
    unreadMessages: number[];
    cognitoUserID: string;
    truck: Truck | null;
};

type DriversGroupState = {
    byCognitoUserID: {[key: string]: DriversGroup};
    searchedCognitoUserIDs: string[] | null;
    allCognitoUserIDs: string[];
};

export type ChatState = {
    missingTruckNumbers: {[key: string]: number} | null;
    unreadInfo: {[key: number]: UnreadInfo} | null;
    searchParams: {truckNumber: string} | null;
    openedDriversGroup: OpenedDriversGroup;
    unreadMessagesCount: number | null;
    messageTextToFill: string | null;
    driversGroups: DriversGroupState;
    clientSocketID: string | null;
    dispatcher: Dispatcher | null;
    messageStatus: MessageStatus;
    pagination: Pagination;
    serverID: string;
    isOpen: boolean;
};

const initialState: ChatState = {
    pagination: {total: 0, page: 1, perPage: 300},
    openedDriversGroup: {
        isAttachmentsUploading: false,
        attachmentsToMessage: null,
        unreadMessages: [],
        cognitoUserID: '',
        truck: null,
    },
    driversGroups: {allCognitoUserIDs: [], byCognitoUserID: {}, searchedCognitoUserIDs: null},
    unreadMessagesCount: null,
    missingTruckNumbers: null,
    messageTextToFill: null,
    messageStatus: 'idle',
    clientSocketID: null,
    searchParams: null,
    dispatcher: null,
    unreadInfo: null,
    isOpen: false,
    serverID: '',
};

const chatReducer = (state = initialState, action): any => {
    switch (action.type) {
        case types.TOGGLE_CHAT_WIDGET: {
            return {...state, isOpen: !state.isOpen};
        }

        case types.DRIVERS_GROUPS_RECEIVED: {
            return mappers.handleReducerByDriversGroupsReceived({state, driversGroups: action.payload.driversGroups});
        }

        case types.MORE_DRIVERS_GROUPS_RECEIVED: {
            return mappers.handleReducerByMoreDriversGroupsReceived({
                state,
                driversGroups: action.payload.driversGroups,
            });
        }

        case types.OPEN_DRIVERS_GROUP: {
            return {
                ...state,
                isOpen: true,
                openedDriversGroup: {...state.openedDriversGroup, cognitoUserID: action.payload.cognitoUserID},
            };
        }

        case types.TRUCK_FOR_DRIVERS_GROUP_RECEIVED: {
            return {...state, openedDriversGroup: {...state.openedDriversGroup, truck: action.payload.truck}};
        }

        case types.INIT_MESSAGES_RECEIVED: {
            const {messages, cognitoUserID} = action.payload;

            return mappers.handleReducerByInitMessagesReceived({state, messages, cognitoUserID});
        }

        case types.DISPATCHER_DATA_RECEIVED: {
            const {dispatcher} = action.payload;

            return {...state, dispatcher, serverID: dispatcher.additionalData[0].serverID};
        }

        case types.MISSING_TRUCK_NUMBERS_RECEIVED: {
            return mappers.handleReducerByMissingTruckNumbersReceived({state, payload: action.payload});
        }

        case types.MORE_MESSAGES_RECEIVED: {
            const {messages, cognitoUserID} = action.payload;

            return mappers.handleReducerByMoreMessagesReceived({state, messages, cognitoUserID});
        }

        case types.LEAVE_DRIVERS_GROUP: {
            return mappers.handleReducerByLeaveDriversGroup({state, cognitoUserID: action.payload.cognitoUserID});
        }

        case types.UNREAD_DRIVERS_GROUPS_INFO_RECEIVED: {
            return mappers.handleReducerByUnreadInfoReceived({state, unreadInfo: action.payload});
        }

        case types.UNREAD_DRIVERS_GROUP_MESSAGES_RECEIVED: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, unreadMessages: action.payload},
            };
        }

        case types.SEARCH_PARAMS_RECEIVED: {
            return mappers.handleReducerBySearchParamsReceived({state, formValues: action.payload.formValues});
        }

        case types.SEARCHED_DRIVERS_CLEARED: {
            return {
                ...state,
                driversGroups: {...state.driversGroups, searchedCognitoUserIDs: null},
                searchParams: null,
            };
        }

        case types.SET_MESSAGE_STATUS: {
            return {...state, messageStatus: action.payload.messageStatus};
        }

        case types.OPEN_DRIVERS_GROUP_WITH_MESSAGE: {
            const {truckNumber, messageText} = action.payload;

            return mappers.handleReducerByOpenDriversGroupWithMessage({state, truckNumber, messageText});
        }

        case types.DRIVERS_GROUP_ALL_MESSAGES_RECEIVED: {
            return mappers.handleReducerByDriversGroupAllMessagesReceived({state, messages: action.payload.messages});
        }

        case types.ONLY_PAGINATION_TOTAL_RECEIVED: {
            return mappers.handleReducerByOnlyPaginationTotalReceived({state, payload: action.payload});
        }

        case types.SET_ATTACHMENTS_UPLOADING: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, isAttachmentsUploading: action.payload},
            };
        }

        case types.PAGINATION_RECEIVED: {
            return {
                ...state,
                pagination: {...state.pagination, ...action.payload},
            };
        }

        case types.ATTACHMENTS_TO_MESSAGE_RECEIVED: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, attachmentsToMessage: action.payload.attachments},
            };
        }

        case types.ATTACHMENTS_TO_MESSAGE_CLEARED: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, attachmentsToMessage: null},
            };
        }

        case types.MARK_AS_READ_BY_EVERY_DISPATCHER: {
            return mappers.handleReducerByMarkMessagesAsReadByEveryDispatcher({state, groupID: action.payload.groupID});
        }

        case types.MARK_AS_READ_BY_DISPATCHER: {
            return mappers.handleReducerByMarkMessagesAsReadByDispatcher({state, groupID: action.payload.groupID});
        }

        case wsTypes.WS_CHAT_DISPATCHER_MESSAGE_RECEIVED: {
            return mappers.handleReducerByNewDispatcherMessageReceived({state, message: action.payload.message});
        }

        case wsTypes.WS_CHAT_READ_BY_EVERY_DISPATCHER_RECEIVED: {
            return mappers.handleReducerByMarkMessagesAsReadByEveryDispatcher({
                state,
                groupID: action.payload?.groupID,
            });
        }

        case wsTypes.WS_CHAT_DRIVER_MESSAGE_RECEIVED: {
            return mappers.handleReducerByNewDriverMessageReceived({state, message: action.payload.message});
        }

        case wsTypes.WS_CHAT_SET_SOCKET_ID: {
            return {...state, clientSocketID: action.payload.webSocket.id, messageStatus: 'idle'};
        }

        case wsTypes.WS_CHAT_SOCKET_ID_CLEARED: {
            return {...state, clientSocketID: null, messageStatus: 'idle'};
        }

        case wsTypes.WS_CHAT_SEND_MESSAGE_ERROR_RECEIVED: {
            return {...state, messageStatus: 'failure'};
        }

        default:
            return {...state};
    }
};

export default chatReducer;
